import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi, defaultDataTags } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface AssessmentProfileAttributes {
  first_name: string
  last_name: string
  email: string
  team_id: string
  personality: {
    degrees: number
    intensity: number
  }
  assessment_id?: string
  profile_id: string
}

export const REQUEST = 'PUBLIC_PROFILE_CREATE_ASSESSMENT_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PUBLIC_PROFILE_CREATE_ASSESSMENT_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: unknown
}
const requestSuccess = (key: string, data: unknown): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PUBLIC_PROFILE_CREATE_ASSESSMENT_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const createAssessmentProfile = (
  attributes: AssessmentProfileAttributes,
) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<unknown>((resolve, reject) => {
      dispatch(request(attributes.email))

      const dataTags: Record<string, string[]> = {}
      defaultDataTags.forEach((types, index) => {
        dataTags[index] = types
      })

      PublicApi()
        .post<APIResponse<unknown>>('public/assessment_profiles', attributes)
        .then(({ data }) => {
          dispatch(requestSuccess(attributes.email, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(attributes.email))
          reject(
            `Error calling createAssessmentProfile (Status: ${
              err.response?.status
            }): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
