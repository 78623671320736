import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'

import { Api } from '@dashboard/lib/api'
import { Personality } from '@dashboard/lib/types'

export function enrichCandidateKey(
  params: EnrichCandidateParams | CreateCandidateFromDiscAnalysisParams,
) {
  return JSON.stringify(params)
}

export const REQUEST = 'JOB_ENRICH_CANDIDATE_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'JOB_ENRICH_CANDIDATE_SUCCESS'
const requestSuccess = (key: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
  }) as const

export const REQUEST_FAILURE = 'JOB_ENRICH_CANDIDATE_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export interface EnrichCandidateParams {
  jobId: string
  profileId: string
  personality: Personality
}

export interface CreateCandidateFromDiscAnalysisParams {
  jobId: string
  requestId: string
  personality: Personality
}

export function enrichCandidate(params: EnrichCandidateParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const { jobId, profileId, personality } = params
      const key = enrichCandidateKey(params)
      dispatch(request(key))

      Api()
        .put(`/jobs/${jobId}/profiles/${profileId}`, { personality })
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch((err: AxiosError) => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling enrichCandidate (Status: ${
              err.response?.status
            }): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}

export function createCandidateUsingDiscAnalysis(
  params: CreateCandidateFromDiscAnalysisParams,
  callback?: () => void,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const { jobId, requestId, personality } = params
      const key = enrichCandidateKey(params)
      dispatch(request(key))

      Api()
        .put(`/jobs/${jobId}/candidate/request/${requestId}`, { personality })
        .then(() => {
          dispatch(requestSuccess(key))
          callback && callback()
          resolve()
        })
        .catch((err: AxiosError) => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling enrichCandidate (Status: ${
              err.response?.status
            }): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
