import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'

export interface CandidateProfileAttributes {
  first_name: string
  last_name: string
  email: string
  team_id: string
  invite_id: string
  /** This is the inviter id */
  profile_id: string
  degrees?: number
  intensity?: number
  myers_briggs_text_type?: string
  enneagram_text_type?: string
  strengths?: string[]
  life_values?: string[]
  big_five?: Record<string, number>
}

export const REQUEST = 'PUBLIC_PROFILE_CREATE_JOB_CANDIDATE_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PUBLIC_PROFILE_CREATE_JOB_CANDIDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'PUBLIC_PROFILE_CREATE_JOB_CANDIDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const createJobAssessmentProfile = (
  attributes: CandidateProfileAttributes,
) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(attributes.email))

      PublicApi()
        .post('public/job_candidates', attributes)
        .then(() => {
          dispatch(requestSuccess(attributes.email))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(attributes.email))
          reject(
            `Error calling createJobAssessmentProfile (Status: ${
              err.response?.status
            }): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
