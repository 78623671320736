import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { AuthUserPrivacy, PlaybookHeaderOptions } from '@dashboard/lib/types'

export interface PlaybookUpdateAttributes {
  name?: string
  privacy?: AuthUserPrivacy
  file?: File | null
  header_url?: string | null
}

interface PlaybookUpdateRequestParams extends PlaybookUpdateAttributes {
  header_options: PlaybookHeaderOptions
}

export const REQUEST = 'PLAYBOOK_UPDATE_START'
interface RequestAction {
  type: typeof REQUEST
  playbookId: string
}
const request = (playbookId: string): RequestAction => ({
  type: REQUEST,
  playbookId,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_UPDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  playbookId: string
}
const requestSuccess = (playbookId: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  playbookId,
})

export const REQUEST_FAILURE = 'PLAYBOOK_UPDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  playbookId: string
  error: unknown
}
const requestFailure = (
  playbookId: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  playbookId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updatePlaybook(
  playbookId: string,
  attributes: PlaybookUpdateAttributes,
  headerOptions: PlaybookHeaderOptions = {},
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(playbookId))
      let payload: FormData | PlaybookUpdateRequestParams | null = null

      const { file } = attributes

      if (file) {
        payload = new FormData()

        const headerOptionKeys = Object.keys(
          headerOptions,
        ) as (keyof PlaybookHeaderOptions)[]

        if (attributes.name) payload.append('name', attributes.name)
        if (attributes.privacy) payload.append('privacy', attributes.privacy)
        if (attributes.file) payload.append('file', attributes.file)

        if (headerOptionKeys.length > 0) {
          headerOptionKeys.forEach(key => {
            ;(payload as FormData).append(
              `header_options[${key}]`,
              String(headerOptions[key]),
            )
          })
        }
      } else {
        payload = { ...attributes, header_options: headerOptions }
      }

      Api()
        .put(`/playbooks/${playbookId}`, payload)
        .then(() => {
          dispatch(requestSuccess(playbookId))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(playbookId, err))
          reject(
            `Error calling updatePlaybook (Status: ${
              err.response?.status
            }): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
