import '../index.scss'
import '@crystal-eyes/global.scss'
import styles from './styles.module.scss'

import React, { useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import type { AppContext, AppProps } from 'next/app'
import Script from 'next/script'
import { ApolloProvider, gql } from '@apollo/client'
import Head from 'next/head'
import { Router } from 'next/router'
import 'react-datepicker/dist/react-datepicker.css'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ErrorBoundary } from 'react-error-boundary'
import GlacierError from '@dashboard/components/GlacierError'
import {
  USE_HUBSPOT_ANALYTICS,
  RUDDER_STACK_WRITE_KEY,
} from '@dashboard/lib/constants'
import { setPageToken } from '@dashboard/lib/user'
import { PK_LIVE } from '@dashboard/lib/constants'
import { pathnameHelper, rsPage } from '@dashboard/lib/analytics'
import { initializeStore } from '@dashboard/store'
import { getApolloClient } from '@dashboard/network/router-apollo-client'

import { fontVariables } from '../styles/fonts'
import { AuthedHeaders } from '@dashboard/lib/api'
import { getAuthedHeaders } from '@dashboard/lib/server'
import App from 'next/app'
import Jimo from '@dashboard/components_v2/third_party/Jimo'
import { SpeedInsights } from '@vercel/speed-insights/next'
import FrontendTracer from '@crystal-eyes/utils/FrontendTracer'
import Statsig from '@dashboard/components_v2/third_party/Statsig'
// import PostHog from '@dashboard/components_v2/third_party/PostHog'

if (typeof window !== 'undefined') FrontendTracer('dashboard-frontend')

const store = initializeStore()
const stripePromise = loadStripe(PK_LIVE)

export const ACCESS_QUERY = gql`
  query GetMeAccess {
    me {
      id
    }
  }
`

type MyAppProps = Pick<AppProps, 'Component' | 'pageProps' | 'router'> & {
  authedHeaders: AuthedHeaders | undefined
}

export default function MyApp({
  Component,
  pageProps,
  router,
  authedHeaders,
}: MyAppProps) {
  const { asPath } = router

  const initGA = () => {
    const windowWithDataLayer = window as unknown as Window & {
      dataLayer: unknown[]
    }
    windowWithDataLayer.dataLayer = windowWithDataLayer.dataLayer || []
    function gtag(...args: unknown[]) {
      windowWithDataLayer.dataLayer.push(args)
    }
    gtag('js', new Date())
    gtag('config', 'UA-57088246-2')
  }

  useEffect(() => {
    initGA()
    setPageToken()

    rsPage(undefined, pathnameHelper(window.location.pathname), {
      path: window.location.pathname,
      search: window.location.search,
      title: document.title,
      url: window.location.href,
    })

    Router.events.on('routeChangeComplete', () => {
      rsPage(undefined, pathnameHelper(window.location.pathname), {
        path: window.location.pathname,
        search: window.location.search,
        title: document.title,
        url: window.location.href,
      })
      setPageToken()
    })
  }, [])

  const url = `https://www.crystalknows.com${asPath}`

  return (
    <div className={`${fontVariables} ${styles.pageContent}`}>
      <Head>
        <link rel="shortcut icon" href="/static/share/images/favicon.ico" />
        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
        />

        {/* New Relic Instrumentation */}
        {process.env.APP_ENV == 'production' && (
          /* eslint-disable-next-line @next/next/no-sync-scripts */
          <script type="text/javascript" src="/static/newrelic.js" />
        )}

        {process.env.APP_ENV == 'staging' && (
          /* eslint-disable-next-line @next/next/no-sync-scripts */
          <script type="text/javascript" src="/static/newrelic.staging.js" />
        )}

        {/* End New Relic Instrumentation */}
      </Head>

      {/* <!-- Start of HubSpot Embed Code --> */}
      {USE_HUBSPOT_ANALYTICS ? (
        <script
          key="hubspot"
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="https://js.hs-scripts.com/1716276.js"
        />
      ) : null}
      {/* <!-- End of HubSpot Embed Code --> */}
      {/* <!-- Start of Jimo Embed Code --> */}
      <Jimo pagesRouter={true} />
      {/* <PostHog /> */}
      {/* <!-- End of Jimo Embed Code --> */}
      {/* <!-- Start of Stripe Embed Code --> */}
      <Script
        key="stripe"
        type="text/javascript"
        src="https://js.stripe.com/v3"
        async
      />
      {/* <!-- End of Stripe Embed Code --> */}
      {/* Google Optimize */}
      <Script
        src="https://www.googleoptimize.com/optimize.js?id=OPT-WQ63Q3B"
        async
        defer
      />
      {/* End Google Optimize */}
      {/* Google Tags */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-57088246-2"
      ></Script>
      {/* End Google Tags */}
      <script
        id="profitwell-js"
        data-pw-auth="040600fb74b389ab97e8540e7df672b5"
        dangerouslySetInnerHTML={{
          __html: `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
            a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
            s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
            })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');`,
        }}
      ></script>
      {/* RudderStack */}
      <script
        dangerouslySetInnerHTML={{
          __html: `rudderanalytics=window.rudderanalytics=[];for(var methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],i=0;i<methods.length;i++){var method=methods[i];rudderanalytics[method]=function(a){return function(){rudderanalytics.push([a].concat(Array.prototype.slice.call(arguments)))}}(method)}rudderanalytics.load("${RUDDER_STACK_WRITE_KEY}","https://crystalknows-dataplane.rudderstack.com")`,
        }}
      />
      <Script
        src="https://cdn.rudderlabs.com/v1/rudder-analytics.min.js"
        async
        defer
      />
      {/* End RudderStack */}
      {/* Google Sign in */}
      <script
        src="https://accounts.google.com/gsi/client"
        async
        defer
        id="gsiScript"
      />
      {/* End Google Sign in */}
      <Script
        type="text/javascript"
        src="https://cdn.useparagon.com/latest/sdk/index.js"
      ></Script>
      <Statsig />
      <GraphQLProvider authedHeaders={authedHeaders}>
        <Provider store={store}>
          {/* @ts-ignore */}
          <Elements stripe={stripePromise}>
            <ErrorBoundary
              fallback={
                <GlacierError
                  pageTitle="Crystal - Something went wrong"
                  title="Whoops, didn't see that one coming."
                  subtitle="Try refreshing the page or returning to the home page."
                  cta={{
                    text: 'Return to home page',
                    href: '/app',
                  }}
                />
              }
            >
              <Component {...pageProps} />
            </ErrorBoundary>
          </Elements>
        </Provider>
      </GraphQLProvider>
      <SpeedInsights sampleRate={0.3} />
    </div>
  )
}

function GraphQLProvider({
  children,
  authedHeaders,
}: {
  children: React.ReactNode
  authedHeaders: AuthedHeaders | undefined
}) {
  const routerApolloClient = useMemo(
    () => getApolloClient(authedHeaders),
    [authedHeaders],
  )

  return <ApolloProvider client={routerApolloClient}>{children}</ApolloProvider>
}

MyApp.getInitialProps = async (appCtx: AppContext) => {
  const ctxProps = await App.getInitialProps(appCtx)
  const authedHeaders = getAuthedHeaders(appCtx.ctx)
  return { ...ctxProps, authedHeaders }
}
